import React, { lazy } from 'react';
import ReactDOM from "react-dom/client";

import { runMode } from './lib/getRunMode';

// Get the pixel widths of the display body
const rootCssObj = window.getComputedStyle(document.getElementById("root"), null);
const displayWidthPixels = parseInt(rootCssObj.getPropertyValue("width"), 10);// ditto

const remInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
const effectiveRem = remInPixels / window.devicePixelRatio;
console.log(`1rem = ${effectiveRem}px (adjusted for zoom)`);
console.log("displayWidthPixels = " + displayWidthPixels);

// Get the pixel widths of both an individual character and the display body
// const characterWidthPixels = parseInt(rootCssObj.getPropertyValue("font-size"), 10);// parseInt strips off the "px"
// const displayWidthCharacters = displayWidthPixels / characterWidthPixels;
// Use these two values to get the width of the display in characters. I reckon that unless you can get more than 60
// characters across your device page, it's too small to be used to display pdf files, so you should serve the
// mobile version of the website, which doesn't offer these

const deviceIsDesktop = (displayWidthPixels >= 840) ? true : false;
const deviceIsMobile = !deviceIsDesktop;

var pageHref = window.location.href;

  // In the first version of main.jsx (currently backed-up in main_pre_lazy.jsx), the mobile version of the
  // webapp had to carry the full weight of the imports for the desktop version. In this current version, lazy
  // loads are used to make the two versions independent of each other. This has involved creating a new
  // DesktopHome component for the code previously held directly within main.jsx itself. Lighthouse gives the
  // new version slightly better scores than previously ( desktop/mobile went from 100/37 down to 97/48) but
  // objective measurement of response with an empty cache show that the desktop version performs about the
  // same, while the mobile version is a lot faster - ie, exactly what you would expect. It's possible that
  // Lighthouse may simply be mis-representing the situation - the treemap displays many duplciated modules. The
  // real mystery is why the webapp still loads crazily fast on an empty cache.

  const MobileHome = lazy(() => import('./routes/MobileHome'));
  const DesktopHome = lazy(() => import('./routes/DesktopHome'))

  if (runMode === "test_") document.body.style.backgroundColor = 'lavender';
  if (runMode === "emul_") document.body.style.backgroundColor = 'thistle';

  const root = ReactDOM.createRoot(
    document.getElementById("root")
  );

  // strict mode removed to enable Google map to work

  // Render an appropriate page. The only mobile pages reaching this point will be those suffixed with "/mobile" urls being used
  // to generate the faked mobile Prerender pages. These will use the <MobileHome> component. Everything else
  // will use <Desktop>

  // the "mobile" bit below is there purely to enable manual creation of faked /mobile SEO pages in Prerender.io . You
  // put these there by asking Prender to cache the following:
  //  https://apparch.org/mobile
  //  https://apparch.org/newsletters/mobile

  // console.log("rendering for " + pageHref)

  if (deviceIsMobile || pageHref.includes("mobile")) {
    root.render(
      <MobileHome />
      // note that https://apparch.org/archive_index/mobile displays the archive_index page
      // because MobileHome provides a routes for this
    );
  } else {
    root.render(
      <DesktopHome />
    );
  }
